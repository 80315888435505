<template>
  <ContentWrapper
      :permissions="['issue-list']"
      class="issue-list"
  >
    <div class="nav-filter d-flex justify-content-between">
      <!--          <div class="d-flex">-->
      <div>
        <template v-if="timeSums">
          <el-tooltip :content="$t('system.estimated_time')">
            <el-tag
                class="border-0"
                color="#00acc1"
                effect="dark"
            >
              {{ timeSums.estimated ? timeSums.estimated.slice(0, -3) : '00:00' }}
            </el-tag>
          </el-tooltip>
          <b class="mx-1">-</b>
          <el-tooltip :content="$t('system.worked_time')">
            <el-tag
                class="border-0"
                color="#007c91"
                effect="dark"
            >
              {{ timeSums.worked ? timeSums.worked.slice(0, -3) : '00:00' }}
            </el-tag>
          </el-tooltip>
          <b class="mx-1">=</b>
          <el-tooltip :content="$t('system.remaining_time')">
            <el-tag
                :color="timeSums.remaining && timeSums.remaining.startsWith('-') ? '#b71c1c' : '#4caf50'"
                class="border-0"
                effect="dark"
            >
              {{ timeSums.remaining ? timeSums.remaining.slice(0, -3) : '00:00' }}
            </el-tag>
          </el-tooltip>
        </template>
      </div>
      <div class="d-flex">
        <DebounceInput
            :placeholder="$t('system.search_by_name')"
            @change="fetchData"
            class="mr-3"
            clearable
            size="small"
            v-model="subjectSearch"
        />
        <el-badge
            :hidden="countOfActiveFilters === 0"
            :value="countOfActiveFilters"
        >
          <el-button-group class="d-flex justify-content-end">
            <el-button
                :style="showConfigurations ? 'background: #74767c':'background: #909399'"
                @click="toggleConfigurations"
                class="outline-none"
                id="dropdown--config-switch-button"
                type="info"
            >
              <font-awesome-icon
                  icon="cogs"
                  id="dropdown--config-switch-icon"
              />
            </el-button>
            <el-button
                :style="showFilters ? 'background: #74767c':'background: #909399'"
                @click="toggleFilters"
                class="outline-none"
                id="dropdown--filter-switch-button"
                type="info"
            >
              <font-awesome-icon
                  icon="filter"
                  id="dropdown--filter-switch-icon"
              />
            </el-button>
          </el-button-group>
        </el-badge>
      </div>
      <!--          </div>-->
    </div>
    <div id="collapseDropdown">
      <transition name="el-fade-in">
        <div
            class="issueFilter issueFilter-shadow"
            v-show="showFilters && !showConfigurations"
        >
          <div class="view">
            <div class="view-body">
              <div class="row">
                <div
                    class="col-3 issue--customFilrers"
                    style="border-right: 1px solid #afb1b6;"
                >
                  <template v-if="listFilterConfig">
                    <el-button-group
                        :key="item.id"
                        class="d-flex"
                        v-for="item in listFilterConfig"
                    >
                      <el-tooltip
                          :content="item.value.title"
                          :open-delay=1000
                          placement="top"
                      >
                        <el-button
                            :class="activeFilter === item.id ? 'issueFilter--button-active' : ''"
                            :type="activeFilter === item.id ? 'info' : ''"
                            @click="setCurrentFilter(item)"
                            class="w-100 issueFilter--button-wrap"
                        >
                          {{ item.value.title }}
                        </el-button>
                      </el-tooltip>
                      <el-button
                          @click="updateFilter(item.id, item.value.title)"
                          size="mini"
                          type="primary"
                          v-if="activeFilter === item.id"
                      >
                        <font-awesome-icon icon="save" />
                      </el-button>
                      <el-button
                          :loading="deleteFilterLoading === item.id"
                          @click="deleteFilter(item.id)"
                          icon="el-icon-delete"
                          size="mini"
                          type="danger"
                      ></el-button>
                    </el-button-group>
                  </template>
                  <span
                      class="no-date-title"
                      v-else
                  >{{ $t('system.no_data') }}</span>
                </div>
                <div class="col-9">
                  <template v-for="(item, index) in filters">
                    <dynamic-filters
                        :data="item"
                        :key="index"
                    ></dynamic-filters>
                  </template>
                  <!--<div class="filters-left-task">-->
                  <!--<vue-form-generator :schema="_self['issueFilter']"></vue-form-generator>-->
                  <!--</div>-->
                  <div class="filters-button">
                    <el-button
                        type="info"
                        v-on:click.native="showNewFilterTitleInput"
                        v-show="showSaveFilterButton"
                    >
                      {{ $t('system.save') }}
                    </el-button>
                    <el-input
                        :class="['issue--newFilterTitle', checkFilterTitle && !newFilterTitle ? 'validate-error' : '']"
                        @keyup.enter.native="saveNewFilter"
                        ref="newFilterTitle"
                        v-model="newFilterTitle"
                        v-observe-visibility="newFilterTitleVisibilityChanged"
                        v-show="!showSaveFilterButton"
                    >
                      <i
                          @click="closeNewFilterTitleInput"
                          class="el-icon-circle-close el-input__icon"
                          slot="suffix"
                      >
                      </i>
                    </el-input>
                    <el-button
                        @click="saveNewFilter"
                        type="primary"
                        v-show="!showSaveFilterButton"
                    >
                      {{ $t('system.save') }}
                    </el-button>
                    <el-button
                        type="info"
                        v-on:click="clearFilters"
                    >{{ $t('system.clear') }}
                    </el-button>
                    <el-button
                        type="primary"
                        v-on:click="fetchData"
                    >{{ $t('system.search') }}
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="el-fade-in">
        <div
            class="issueFilter issueFilter-shadow"
            v-show="showConfigurations && !showFilters"
        >
          <div class="issue--transfer">
            <div class="view">
              <div class="view-body">
                <div class="row">
                  <div
                      class="col-3 issue--customFilrers"
                      style="border-right: 1px solid #afb1b6;"
                  >
                    <!--<el-button type="info" @click.prevent.stop="defaultConfig"-->
                    <!--class="issue&#45;&#45;customColumnsConfig-defaultConfig">-->
                    <!--{{$t('system.default_system')}}-->
                    <!--</el-button>-->
                    <template v-if="listColumnConfig">
                      <el-button-group
                          :key="item.id"
                          class="d-flex"
                          v-for="item in listColumnConfig"
                      >
                        <el-tooltip
                            :content="item.value.title"
                            :open-delay=1000
                            placement="top"
                        >
                          <el-button
                              :class="[activeColumn === item.id ? 'issueFilter--button-active' : '']"
                              :type="activeColumn === item.id ? 'info' : ''"
                              @click="setCurrentColumn(item)"
                              class="w-100 issueFilter--button-wrap"
                          >
                            {{ item.value.title }}
                          </el-button>
                        </el-tooltip>
                        <el-button
                            @click="updateColumn(item.id, item.value.title)"
                            size="mini"
                            type="primary"
                            v-if="activeColumn === item.id && checkDiff(item)"
                        >
                          <font-awesome-icon icon="save" />
                        </el-button>
                        <el-button
                            :loading="deleteColumnLoading === item.id"
                            @click="deleteColumn(item.id)"
                            icon="el-icon-delete"
                            size="mini"
                            type="danger"
                        ></el-button>
                      </el-button-group>
                    </template>
                    <span
                        class="no-date-title"
                        v-else
                    >{{ $t('system.no_data') }}</span>
                  </div>
                  <div class="col-9">
                    <!--                    <el-transfer-->
                    <!--                        filterable-->
                    <!--                        target-order="push"-->
                    <!--                        :filter-placeholder="$t('system.enter_the_name_of_the_search')"-->
                    <!--                        :titles="[$t('issue.no-checked'), $t('issue.checked')]"-->
                    <!--                        v-model="selectTheadWatcher"-->
                    <!--                        :data="thead">-->
                    <!--                    </el-transfer>-->
                    <DraggableList
                        class="columns-selector"
                        v-model="selectTheadWatcher"
                    />
                    <div class="filters-button">
                      <!--<el-button type="info" @click.prevent.stop="clearConfig">{{$t('system.clear_filters')}}</el-button>-->
                      <el-button
                          @click="showNewColumnsConfigTitleInput"
                          type="primary"
                          v-show="showSaveColumnsConfigButton"
                      >
                        {{ $t('system.save_configs') }}
                      </el-button>
                      <el-input
                          :class="['issue--newFilterTitle', checkColumnTitle && !newColumnsConfigTitle ? 'validate-error' : '']"
                          @keyup.enter.native="saveNewColumn"
                          ref="newColumnsConfigTitle"
                          v-model="newColumnsConfigTitle"
                          v-observe-visibility="newColumnsConfigTitleVisibilityChanged"
                          v-show="!showSaveColumnsConfigButton"
                      >
                        <i
                            @click="closeNewColumnsConfigTitleInput"
                            class="el-icon-circle-close el-input__icon"
                            slot="suffix"
                        >
                        </i>
                      </el-input>
                      <el-button
                          @click="saveNewColumn"
                          type="primary"
                          v-show="!showSaveColumnsConfigButton"
                      >
                        {{ $t('system.save') }}
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <hr style="margin-bottom: 5px">
    <DataTable
        :headers="[
        {key: 'indicator', label: '', width: '10px'},
        ...selectedColumns.map(item => ({key: item, label: `${item}`, justify: 'center'}))
      ]"
        :idPath="tableIdentifierColumn"
        :loading="tableLoading"
        :min-headers-length="2"
        :model="model"
        :params="params"
        @clickRow="onClickRow"
        @updateData="fetchData"
    >
      <template v-slot:header.indicator>
        <div class="indicator"></div>
      </template>
      <template v-slot:item.indicator="{row}">
        <div
            class="d-flex fill-height"
            style="background-color: #ff4949"
        >
          {{ row.indicator }}
        </div>
      </template>
      <template v-slot:item.projects.name="{row}">
        <div class="d-flex justify-content-center mx-2">
          <a
              :href="`project/${row['projects.id']}`"
              @click.stop.prevent="goToProject(row['projects.id'])"
          >
            <span class="project-name--link--hover">{{ row['projects.name'] }}</span>
          </a>
        </div>
      </template>
      <template v-slot:item.issues.subject="{row}">
        <div class="d-flex justify-content-start mx-2">
          <span style="text-overflow: ellipsis; overflow: hidden;">
            {{ row['issues.subject'] }}
          </span>
        </div>
      </template>
      <template v-slot:item.issues.done_ratio="{row}">
        <div
            class="custom--progress d-flex justify-content-center align-items-center mx-2"
            style="max-width: 175px"
        >
          <div class="position-relative">
            <el-progress
                :percentage="row['issues.done_ratio']"
                :show-text=false
                color="#ff4949"
                style="width: 130px; border-color: #ff4949"
            />
            <div
                class="position-absolute"
                style="top: 5px; left: 40%"
            >
              <b>{{ row['issues.done_ratio'] }}%</b>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:item.author.name="{row}">
        <div class="mx-2 d-flex justify-content-center align-items-center">
          <img
              :alt="row['author.name']"
              :src="getAvatarById(row['author.id'])"
              class="avatar"
              v-if="getAvatarById(row['author.id'])"
          >
          <span
              class="avatar--ifNotImage"
              v-else
          >
            {{ abbreviation(row['author.name']) }}
          </span>
          <span class="ml-2">
            {{ row['author.name'] }}
          </span>
        </div>
      </template>
      <template v-slot:item.users.name="{row}">
        <div class="mx-2 d-flex justify-content-center align-items-center">
          <img
              :alt="row['users.name']"
              :src="getAvatarById(row['users.id'])"
              class="avatar"
              v-if="getAvatarById(row['users.id'])"
          >
          <span
              class="avatar--ifNotImage"
              v-else
          >
            {{ abbreviation(row['users.name']) }}
          </span>
          <span class="ml-2">
            {{ row['users.name'] }}
          </span>
        </div>
      </template>
      <template v-slot:item.issues.estimated_hours="{row}">
        <div class="d-flex align-items-center justify-content-center mx-2">
          {{ HHmmssToHHHmm(row['issues.estimated_hours']) }}
        </div>
      </template>
    </DataTable>
    <el-button
        @click="isGenerateReportModalOpened = true"
        class="mt-2"
        type="primary"
    >
      {{ $t('system.generate_report') }}
    </el-button>
    <GenerateReportModal
        :loading="generateReportLoading"
        :title="$t('system.generate_report')"
        @close="isGenerateReportModalOpened = false"
        @generate="generateReport"
        is-visible
        v-if="isGenerateReportModalOpened"
    />
  </ContentWrapper>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import axios from 'axios';
import {isEqual, sortBy} from 'lodash';
import notification from '../../notification/notify';
import ContentWrapper from '../../components/HOCs/ContentWrapper.vue';
import {abbreviation, saveFile} from '../../helpers/functions';
import DebounceInput from '../../components/DebounceInput';
import DataTable from '../../components/Table/DataTable';
import {HHmmssToHHHmm} from '../../helpers/time';
import issueMixin from '../../mixin/issue';
import {distinct} from '@/helpers/arrayFunctions';
import DraggableList from '@/components/DraggableList/DraggableList';

// TODO: refactor component
export default {
  name: 'listTracker',
  mixins: [issueMixin],
  components: {
    DraggableList,
    DataTable,
    DebounceInput,
    GenerateReportModal: () => import('../../components/GenerateReportModal/GenerateReportModal.vue'),
    ContentWrapper,
    'dynamic-filters': () => import('../../components/Filters/dynamicFilters.vue'),
  },
  props: ['projectId', 'idTracker'],
  data() {
    return {
      usersAvatars: [],
      tableIdentifierColumn: 'issues.id',
      timeSums: {
        estimated: null,
        worked: null,
        remaining: null,
      },
      tableLoading: true,
      newFilterTitle: '',
      newColumnsConfigTitle: '',
      showSaveFilterButton: true,
      showSaveColumnsConfigButton: true,
      thead: [],
      selectThead: [],
      subjectSearch: '',
      activeFilter: null,
      activeColumn: null,
      // showDropdown: false,
      showFilters: false,
      showConfigurations: false,
      dropdownSelectors: ['collapseDropdown',
        'dropdown--filter-switch-button',
        'dropdown--config-switch-button',
        'dropdown--filter-switch-icon',
        'dropdown--config-switch-icon',
      ],
      filters: [],
      listFilterConfig: [],
      listColumnConfig: [],
      checkFilterTitle: false,
      checkColumnTitle: false,
      columnDefault: {},
      checkDiffActive: false,
      deleteFilterLoading: '',
      deleteColumnLoading: '',
      isGenerateReportModalOpened: false,
      generateReportLoading: false,
      params: {
        column: 'issues.id',
        direction: 'desc',
        per_page: 20,
        page: 1,
        search_column: 'id',
        total: 0,
      },
      model: [],
    };
  },
  beforeDestroy() {
    this.model = [];
    this.thead = [];
    this.selectThead = [];
  },
  async beforeMount() {
    if (this.ifHasPermission(['issue-list'])) {
      this.tableLoading = true;
      this.setParamsFromQueryString();
      // TODO: zmienić sprawdzenie po dodaniu konfiguracji tableki do linku
      await this.getFiltersApi(this.idTracker).then((response) => {
        this.filters = response;
      });
      // fetch filters presets
      let paramsFilterConfig = {
        idTracker: this.idTracker,
        key: 'filter',
      };
      this.getFiltersOrColumnsConfigApi(paramsFilterConfig).then((response) => {
        this.listFilterConfig = response.data;
      });
      // fetch columns presets
      let paramsColumnConfig = {
        idTracker: this.idTracker,
        key: 'column',
      };
      this.getFiltersOrColumnsConfigApi(paramsColumnConfig).then((response) => {
        this.listColumnConfig = response.data;
      });
      // parse queryString
      if (Object.keys(this.$route.query).length) {
        this.setFiltersFromQuery();
      } else {
        let currentFilter = this.getFilters.find(item => item.idTracker === this.idTracker);
        if (currentFilter) {
          this.setCheckedFiltersToQuery(currentFilter.checkedFilters);
          this.setFiltersToQuery(currentFilter.selectedValues);
        }
      }
      // fetch columns list
      await this.getColumnsList();
      // fetch default columns preset
      await this.getDefaultColumn();

      await this.fetchData();

      this.tableLoading = false;
    }
  },
  mounted() {
    if (this.ifHasPermission(['issue-list'])) {
      this.closeQuickFilterDropdownIfClickedOutside();
    }
  },
  watch: {
    getFilters(newVal) {
      let currentFilter = newVal.find(item => item.idTracker === this.idTracker);
      if (!currentFilter) return;
      this.setCheckedFiltersToQuery(currentFilter.checkedFilters);
      this.setFiltersToQuery(currentFilter.selectedValues);
    },
    async idTracker() {
      this.tableLoading = true;
      await this.getColumnsList();
      await this.getDefaultColumn();
      let currentFilter = this.getFilters.find(item => item.idTracker === this.idTracker);
      if (currentFilter) {
        this.setCheckedFiltersToQuery(currentFilter.checkedFilters);
        this.setFiltersToQuery(currentFilter.selectedValues);
      }
      this.getFiltersApi(this.idTracker).then((response) => {
        this.filters = response;
      });
      let paramsFilterConfig = {
        idTracker: this.idTracker,
        key: 'filter',
      };
      this.getFiltersOrColumnsConfigApi(paramsFilterConfig).then((response) => {
        this.listFilterConfig = response.data;
      });
      let paramsColumnConfig = {
        idTracker: this.idTracker,
        key: 'column',
      };
      this.getFiltersOrColumnsConfigApi(paramsColumnConfig).then((response) => {
        this.listColumnConfig = response.data;
      });
      await this.fetchData();
      this.tableLoading = false;
    },
  },
  computed: {
    ...mapGetters(['issueFilter', 'uConfig', 'systemSetting', 'savedColumns', 'currentProjectId']),
    ...mapGetters('filters', ['getFilters', 'getSelectedColumns', 'getDefaultColumns', 'getFiltersList']),
    selectedColumns() {
      return this.getSelectedColumns(this.idTracker);
    },
    countOfActiveFilters() {
      const currentFilter = this.getFilters.find(item => item.idTracker === this.idTracker.toString());
      if (currentFilter) {
        const keys = Object.keys(currentFilter.selectedValues).map((key) => {
          if (currentFilter.selectedValues[key]) {
            if ((Array.isArray(currentFilter.selectedValues[key]) && currentFilter.selectedValues[key].length) ||
                currentFilter.selectedValues[key].toString().length) {
              return key;
            }
          }
        });
        let activeFilters = keys.filter((value) => {
          return currentFilter.checkedFilters[value];
        });
        return activeFilters.length;
      } else {
        return 0;
      }
    },
    selectTheadWatcher: {
      get() {
        return this.thead;
      },
      set(val) {
        this.thead = [...val];
        this.$store.commit('filters/ADD_COLUMNS', {
          idTracker: this.idTracker,
          val: val.filter(item => item.isActive).map(item => item.key),
        });
        this.fetchData();
      },
    },
  },
  methods: {
    ...mapActions('filters', ['getFiltersApi', 'createFilterConfig', 'getFiltersOrColumnsConfigApi', 'deleteFilterOrColumnConfig', 'updateFilterConfig', 'updateColumnConfig', 'selectColumns', 'setColumnsConfigAsDefault']),
    ...mapActions('issue', ['searchIssues']),
    getAvatarById(id) {
      const candidate = this.usersAvatars.find(avatar => avatar.id === id);
      return candidate?.img;
    },
    HHmmssToHHHmm(time) {
      return HHmmssToHHHmm(time);
    },
    abbreviation(str) {
      return abbreviation(str);
    },
    onClickRow(id) {
      const issuesIds = this.model.map((item) => {
        return item[this.tableIdentifierColumn];
      });
      this.openIssue(id, issuesIds);
    },
    goToProject(projectId) {
      this.$router.push({name: 'Project Menu', params: {projectId}});
    },
    getPropertiesThatStartsWith(str, obj) {
      const result = [];
      Object.keys(obj).forEach((key) => {
        if (key.startsWith(str)) {
          result.push({
            key,
            value: obj[key],
          });
        }
      });
      return result;
    },
    setParamsFromQueryString() {
      const tableParams = this.getPropertiesThatStartsWith('t_', this.$route.query);
      if (tableParams) {
        tableParams.forEach((item) => {
          let validValue = item.value;
          if (!isNaN(validValue)) {
            validValue = parseInt(validValue);
          }
          this.params[item.key.replace(/^.{2}/, '')] = validValue;
        });
      }
    },
    async fetchData() {
      this.showFilters = false;
      if (this.getSelectedColumns(this.idTracker).length === 0) {
        return;
      }
      const currentFilter = this.getFilters.find(item => item.idTracker === this.idTracker);
      const filter = {};
      if (currentFilter) {
        Object.keys(currentFilter.checkedFilters).forEach((item) => {
          if (currentFilter.checkedFilters[item]) {
            filter[item] = currentFilter.selectedValues[item];
          }
        });
      }
      // this.tableLoading = true;
      const data = {
        columns: this.getSelectedColumns(this.idTracker),
        sortBy: {
          column: this.params.column,
          direction: this.params.direction,
        },
        per_page: this.params.per_page,
        page: this.params.page,
        filters: filter,
      };
      //static filter fields
      data.filters['issues.form_id'] = this.idTracker;
      if (this.projectId && (!data.filters['projects.id'] || data.filters['projects.id'].length === 0)) {
        const projectsFilter = this.getFiltersList.find((item) => item.label === 'projects.id');
        if (projectsFilter) {
          data.filters['projects.id'] = projectsFilter.values ? projectsFilter.values.map((item) => item.id) : [];
        }
      }
      data.filters.search = this.subjectSearch;
      await this.searchIssues({data: data, url: 'issue-list'}).then(response => {
        this.model = response.data.model.data;
        this.params.total = response.data.model.total;
        this.setTimeSums(response.data.timeSums);

        if (this.selectedColumns.some(column => column === 'users.name' || column === 'author.name')) {
          // get avatars ids
          const usersIds = [];
          this.model.forEach(issue => {
            if (issue['users.id']) usersIds.push(issue['users.id']);
            if (issue['author.id']) usersIds.push(issue['author.id']);
          });
          const uniqueUsersIds = distinct(usersIds);
          const idsToFetch = uniqueUsersIds.filter(x => !this.usersAvatars.map(item => item.id).includes(x));
          const idsToRemove = this.usersAvatars.map(item => item.id).filter(x => !uniqueUsersIds.includes(x));

          // fetch avatars
          this.fetchAvatars(idsToFetch);

          // remove unused avatars
          this.usersAvatars = this.usersAvatars.filter(item => !idsToRemove.includes(item.id));
        }
      });
      // this.tableLoading = false
      this.setTableConfigToQueryString(this.params);
    },
    async fetchAvatars(avatarsIds) {
      return Promise.all(avatarsIds.map((avatarId, index) =>
          axios.get(`avatar/${avatarId}`, {
            responseType: 'blob',
            params: {type: 'mime_type'},
          }).then(avatarResponse => {
            if (avatarResponse.status === 200 && avatarResponse.data?.size) {
              const reader = new FileReader();
              reader.readAsDataURL(avatarResponse.data);
              reader.onload = () => {
                const candidateIdx = this.usersAvatars.findIndex(item => item.id === avatarsIds[index]);
                if (candidateIdx !== -1) {
                  this.usersAvatars[candidateIdx].img = reader.result;
                } else {
                  this.usersAvatars.push({
                    id: avatarsIds[index], img: reader.result,
                  });
                }
              };
            } else {
              this.usersAvatars.push({id: avatarsIds[index]});
            }
          }),
      ));
    },
    async generateReport(type, options) {
      this.generateReportLoading = true;
      // prepare request body
      const data = {
        filters: {},
        type,
        columns: this.getSelectedColumns(this.$route.params.idTracker),
      };
      Object.keys(options).forEach(key => {
        if (options[key] !== null && options[key] !== undefined) {
          data[key] = options[key];
        }
      });
      const currentFilter = this.getFilters.find(item => item.idTracker === this.$route.params.idTracker);
      if (currentFilter) {
        Object.keys(currentFilter.checkedFilters).forEach((item) => {
          if (currentFilter.checkedFilters[item]) {
            data.filters[item] = currentFilter.selectedValues[item];
          }
        });
      }
      if (this.$route.params.projectId && (!data.filters['projects.id'] || data.filters['projects.id'].length === 0)) {
        const projectsFilter = this.getFiltersList.find((item) => item.label === 'projects.id');
        if (projectsFilter) {
          data.filters['projects.id'] = projectsFilter.values ? projectsFilter.values.map((item) => item.id) : [];
        }
      }
      //----------
      try {
        const response = await axios.post('issue/report', data, {responseType: 'arraybuffer'});
        saveFile(response, `Report.${type}`);
      } catch (e) {
        notification.notify(
            this.$t('system.error'),
            this.$t('system.report_was_not_generated'),
            'error',
        );
      } finally {
        this.generateReportLoading = false;
      }
    },
    setTimeSums(timeSums) {
      this.timeSums = {...timeSums};
    },
    setFiltersToQuery(newVal) {
      let filterIdentifier = 'f_';
      // DELETING FILTER OBJECTS
      let queryString = {...this.$route.query};
      Object.keys(queryString).forEach(key => {
        if (key.startsWith(filterIdentifier)) {
          delete queryString[key];
        }
      });
      // ADDING NEW FILTERS
      let selectedValues = {};
      Object.keys(newVal).forEach(key => {
        if (newVal[key]) {
          selectedValues[filterIdentifier + key] = newVal[key];
        }
      });
      // MERGING
      queryString = {...queryString, ...selectedValues};
      // SETTING TO QUERY
      this.$router.replace({
        query: queryString,
      }).catch(() => {
      });
    },
    setCheckedFiltersToQuery(newVal) {
      let checkIdentifier = 'ch_';
      // ADDING CHECKBOXES
      let queryString = {...this.$route.query};
      delete queryString[checkIdentifier];
      let checked = [];
      Object.keys(newVal).forEach(key => {
        if (newVal[key]) {
          checked.push(key);
        }
      });
      // MERGING
      queryString[checkIdentifier] = checked;
      // SETTING TO QUERY
      this.$router.replace({
        query: queryString,
      }).catch(() => {
      });
    },
    setFiltersFromQuery() {
      let vm = this;
      Object.keys(this.$route.query).forEach(key => {
        const value = this.$route.query[key];
        if (key.startsWith('f_')) {
          let parsedValue = null;
          if (Array.isArray(value)) {
            if (key.endsWith('_at')) {
              parsedValue = value;
            } else {
              parsedValue = value.map((item) => {
                return parseInt(item);
              });
            }
          } else if (isNaN(value)) {
            parsedValue = value;
          } else if (value) {
            parsedValue = parseInt(value);
          }
          this.$store.commit('filters/SET_ISSUE_FILTER_VALUE', {
            filter: key.replace(/^.{2}/, ''),
            value: parsedValue,
            idTracker: this.idTracker,
          });
        } else if (key.startsWith('ch_')) {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              vm.$store.commit('filters/SET_ISSUE_FILTER_TOOGLE', {
                filter: item,
                value: true,
                idTracker: vm.$route.params.idTracker,
              });
            });
          } else {
            vm.$store.commit('filters/SET_ISSUE_FILTER_TOOGLE', {
              filter: value,
              value: true,
              idTracker: vm.$route.params.idTracker,
            });
          }
        }
      });
    },
    setTableConfigToQueryString(newParams) {
      let filterIdentifier = 't_';
      let tableConfig = {};
      Object.keys(newParams).forEach((key) => {
        tableConfig[filterIdentifier + key] = newParams[key];
      });
      let queryString = {...this.$route.query, ...tableConfig};
      this.$router.replace({
        name: this.$route.name,
        query: queryString,
      }).catch(() => {
      });
    },
    newFilterTitleVisibilityChanged() {
      this.$refs.newFilterTitle.focus();
    },
    newColumnsConfigTitleVisibilityChanged() {
      this.$refs.newColumnsConfigTitle.focus();
    },
    showNewFilterTitleInput() {
      this.showSaveFilterButton = false;
    },
    showNewColumnsConfigTitleInput() {
      this.showSaveColumnsConfigButton = false;
    },
    closeNewFilterTitleInput() {
      this.showSaveFilterButton = true;
      this.newFilterTitle = '';
    },
    closeNewColumnsConfigTitleInput() {
      this.showSaveColumnsConfigButton = true;
      this.newColumnsConfigTitle = '';
    },
    deleteColumn(idUserConfig) {
      let vm = this;
      vm.deleteColumnLoading = idUserConfig;
      this.deleteFilterOrColumnConfig(idUserConfig).then(() => {
        let paramsColumnConfig = {
          idTracker: vm.$route.params.idTracker,
          key: 'column',
        };
        this.getFiltersOrColumnsConfigApi(paramsColumnConfig).then(function (response) {
          vm.listColumnConfig = response.data;
          notification.notify(
              vm.$t('notify.success'),
              vm.$t('notify.deleted'),
              'success');
        });
      }).catch(function (error) {
        notification.notify(
            this.$t('notify.error'),
            error.response.data.error,
            'error');
      });
    },
    updateColumn(idUserConfig, title) {
      let columnsConfig = {
        title: title,
        columns: [...this.selectedColumns],
        idTracker: this.idTracker,
      };
      if (!columnsConfig) {
        notification.notify(
            this.$t('notify.info'),
            this.$t('system.no_filters_selected'),
            'info');
        return;
      }
      this.updateColumnConfig({idUserConfig: idUserConfig, columnsConfig: columnsConfig}).then(() => {
        let paramsColumnConfig = {
          idTracker: this.$route.params.idTracker,
          key: 'column',
        };
        this.getFiltersOrColumnsConfigApi(paramsColumnConfig).then((response) => {
          this.listColumnConfig = response.data;
        });
        notification.notify(
            this.$t('notify.success'),
            this.$t('message.updated'),
            'success');
      });
    },
    getDefaultColumn() {
      let key = 'columnDefault_' + this.idTracker;
      let paramsColumnConfig = {
        idTracker: this.idTracker,
        key: key,
      };
      if (!this.selectedColumns.length) {
        this.getFiltersOrColumnsConfigApi(paramsColumnConfig).then((response) => {
          // if (response.data.length) {
          this.selectTheadWatcher = this.thead.map(item =>
              ({...item, isActive: response.data[0]?.value?.columns?.includes(item.key) || false}),
          );
          this.$store.commit('filters/SET_DEFAULT_COLUMN', response.data[0]?.value?.columns || []);
          this.columnDefault = response.data[0] || {};
          // }
        });
      }
    },
    updateFilter(idUserConfig, title) {
      let currentFilter = this.getFilters.find(item => item.idTracker === this.idTracker);
      if (!currentFilter) {
        notification.notify(
            this.$t('notify.info'),
            this.$t('system.no_filters_selected'),
            'info');
        return;
      }
      currentFilter.title = title;
      this.updateFilterConfig({idUserConfig: idUserConfig, currentFilter: currentFilter}).then(() => {
        let paramsFilterConfig = {
          idTracker: this.$route.params.idTracker,
          key: 'filter',
        };
        this.getFiltersOrColumnsConfigApi(paramsFilterConfig).then((response) => {
          this.listFilterConfig = response.data;
        });
        notification.notify(
            this.$t('notify.success'),
            this.$t('notify.updated'),
            'success');
      });
    },
    deleteFilter(idUserConfig) {
      let vm = this;
      vm.deleteFilterLoading = idUserConfig;
      this.deleteFilterOrColumnConfig(idUserConfig).then(() => {
        let paramsFilterConfig = {
          idTracker: vm.$route.params.idTracker,
          key: 'filter',
        };
        this.getFiltersOrColumnsConfigApi(paramsFilterConfig).then(function (response) {
          vm.listFilterConfig = response.data;
          notification.notify(
              vm.$t('notify.success'),
              vm.$t('notify.deleted'),
              'success');
        });
      }).catch(function (error) {
        notification.notify(
            this.$t('notify.error'),
            error.response.data.error,
            'error');
      });
    },
    saveNewFilter() {
      let vm = this;
      if (!this.newFilterTitle) {
        this.checkFilterTitle = true;
        return;
      }
      let currentFilter = this.getFilters.find(item => item.idTracker === this.idTracker);
      if (!currentFilter) {
        notification.notify(
            vm.$t('notify.info'),
            vm.$t('system.no_filters_selected'),
            'info');
        return;
      }
      currentFilter.title = this.newFilterTitle;
      this.createFilterConfig({value: currentFilter, key: 'filter'}).then(() => {
        this.showSaveFilterButton = true;
        this.newFilterTitle = '';
        let paramsFilterConfig = {
          idTracker: vm.$route.params.idTracker,
          key: 'filter',
        };
        this.getFiltersOrColumnsConfigApi(paramsFilterConfig).then(function (response) {
          vm.listFilterConfig = response.data;
        });
        notification.notify(
            vm.$t('notify.success'),
            vm.$t('message.added'),
            'success');
        this.checkFilterTitle = false;
      }).catch(function () {
        notification.notify(
            this.$t('notify.error'),
            this.$t('message.filter_is_already_exist'),
            'error');
      });
    },
    saveNewColumn() {
      let vm = this;
      if (!this.newColumnsConfigTitle) {
        this.checkColumnTitle = true;
        return;
      }
      let columnsConfig = {
        title: this.newColumnsConfigTitle,
        columns: [...this.selectedColumns],
        idTracker: vm.$route.params.idTracker,
      };
      if (!columnsConfig) {
        notification.notify(
            vm.$t('notify.info'),
            vm.$t('system.no_filters_selected'),
            'info');
        return;
      }
      this.createFilterConfig({value: columnsConfig, key: 'column'}).then(() => {
        this.showSaveColumnsConfigButton = true;
        this.newColumnsConfigTitle = '';
        let paramsColumnConfig = {
          idTracker: vm.$route.params.idTracker,
          key: 'column',
        };
        this.getFiltersOrColumnsConfigApi(paramsColumnConfig).then(function (response) {
          vm.listColumnConfig = response.data;
        });
        notification.notify(
            vm.$t('notify.success'),
            vm.$t('message.added'),
            'success');
        this.checkColumnTitle = false;
      }).catch(function () {
        notification.notify(
            this.$t('notify.error'),
            this.$t('message.filter_is_already_exist'),
            'error');
      });
    },
    checkDiff(item) {
      if (isEqual(sortBy(item.value.columns), sortBy(this.selectedColumns))) {
        this.checkDiffActive = false;
        return false;
      } else {
        this.checkDiffActive = true;
        return true;
      }
    },
    setCurrentColumn(column) {
      if (column && column.value) {
        this.activeColumn = column.id;
        this.selectTheadWatcher = this.thead.map(item =>
            ({...item, isActive: column.value?.columns?.includes(item.key)}),
        );
        this.setColumnsConfigAsDefault({
          columnsConfigId: column.id,
          trackerId: this.idTracker,
        });
      }
    },
    setCurrentFilter(filter) {
      if (filter && filter.value) {
        this.activeFilter = filter.id;
        this.$store.commit('filters/CLEAR_CURRENT_FILTER', {idTracker: filter.value.idTracker});
        Object.keys(filter.value.checkedFilters).forEach((key) => {
          this.$store.commit('filters/SET_ISSUE_FILTER_TOOGLE', {
            filter: key,
            value: filter.value.checkedFilters[key],
            idTracker: filter.value.idTracker,
          });
        });
        Object.keys(filter.value.selectedValues).forEach((key) => {
          this.$store.commit('filters/SET_ISSUE_FILTER_VALUE', {
            filter: key,
            value: filter.value.selectedValues[key],
            idTracker: filter.value.idTracker,
          });
        });
        // _.forEach(filter.value.selectedValues, (item, key) => {
        //   this.$store.commit('filters/SET_ISSUE_FILTER_VALUE', {
        //     filter: key,
        //     value: item,
        //     idTracker: filter.value.idTracker
        //   });
        // })
      }
    },
    closeQuickFilterDropdownIfClickedOutside() {
      document.addEventListener('click', (ev) => {
        if (this.checkIfClickedOutside(ev)) {
          return 0;
        } else {
          if (this.showFilters) {
            this.showFilters = false;
          } else if (this.showConfigurations) {
            this.showConfigurations = false;
          }
        }
      });
    },
    checkIfClickedOutside(ev) {
      let dropdown = document.getElementById('collapseDropdown');
      return this.dropdownSelectors.find(selector => {
        return (dropdown && dropdown.contains(ev.target)) || selector === ev.target.id || selector === ev.target.parentElement.id || (ev.target.closest(selector) && ev.target.closest(selector).id === selector);
      });
    },
    toggleFilters() {
      if (this.showConfigurations) {
        this.showFilters = true;
        this.showConfigurations = false;
      } else {
        this.showFilters = !this.showFilters;
      }
    },
    toggleConfigurations() {
      if (this.showFilters) {
        this.showFilters = false;
        this.showConfigurations = true;
      } else {
        this.showConfigurations = !this.showConfigurations;
      }
    },
    clearFilters() {
      window.history.pushState({}, '', this.$route.path);
      this.$store.commit('filters/CLEAR_CURRENT_FILTER', {idTracker: this.idTracker});
    },
    async getColumnsList() {
      // this.tableLoading = true
      return axios.get(`tracker-columns?trackerId=${this.idTracker}`)
          .then((response) => {
            this.thead = response.data.data.map((item) => {
              return {
                key: item,
                label: this.$t(item),
              };
            });
          });
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.issue-list {
  .project-name--link--hover {
    &:hover {
      text-decoration: underline;
    }
  }

  .avatar {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    vertical-align: middle;
  }
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.nav-filter {
  .el-badge__content, .el-badge__content--undefined {
    z-index: 1;
  }
}

.issueFilter--button-active {
  max-width: 118px;
  min-width: 118px;
}

.issueColumn--button-active {
  max-width: 119px;
  min-width: 119px;
}

.issueFilter--button-wrap, .issueColumn--button-wrap {
  max-width: 160px;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}

.issueFilter {
  position: absolute;
  background-color: #fff;
  right: 2%;
  z-index: 999;
  overflow: hidden;
  border-radius: 5px;
  width: 950px;

  .columns-selector {
    height: 350px;
    overflow-y: auto;
  }

  .issue--customFilrers {
    height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.60em !important;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 2.5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2.5px;
      background: rgba(144, 147, 153, .3);
      /*border-right: 0.15em solid white;*/
    }

    .el-button-group {
      width: 100%;
      margin: 5px 0;

      .issue--filterTitle {
        width: 72%;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          max-width: 100px;
        }
      }

      .issue--filterDeleteButton {
        width: 28%;
      }
    }
  }

  .issue--transfer {
    width: 100%;
    height: 440px;
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
  }

  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

  .filters-button {
    float: right;
    margin-top: 20px;

    .issue--newFilterTitle {
      width: 200px;
      margin-right: 10px;

      .el-input__icon:hover {
        cursor: pointer;
        color: #909399;
      }
    }
  }

  .el-transfer {
    display: flex !important;
    max-height: 340px;
    /*padding-bottom: 20px;*/
    align-items: center;
    height: calc(100% - 60px) !important;

    .el-transfer-panel {
      width: 100%;
      height: 100%;

      .el-transfer-panel__body {
        height: calc(100% - 60px);

        .el-transfer-panel__filter {
          margin: 15px 15px 7.5px 15px;
        }

        .el-transfer-panel__list.is-filterable {
          height: calc(100% - 35px);

          .el-checkbox {
            display: block;
          }
        }
      }
    }
  }
}

.issueFilter-shadow {
  box-shadow: 0px 0px 14px rgba(0, 0, 0, .1);
}

</style>
