<template>
  <div>
    <Draggable
        :animation="100"
        :value="value"
        @input="onChange"
        class="list-group"
        handle=".handle"
        tag="ul"
    >

      <DraggableListItem
          :key="item.id"
          :text="item.key"
          class="list-group-item"
          tag="li"
          v-for="item in value"
      >
        <div class="item">
          <span class="text item__text">{{ $t(item.key) }}</span>
          <span class="item__switch">
            <el-switch
                :value="item.isActive"
                @change="changeIsActiveValue(item)"
            />
          </span>
        </div>
      </DraggableListItem>

    </Draggable>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import DraggableListItem from '@/components/DraggableList/ListItem';

export default {
  name: 'DraggableList',
  components: {
    DraggableListItem,
    Draggable,
  },
  props: {
    value: {
      type: Array,
    },
  },
  methods: {
    onChange(newOrderedArray) {
      this.$emit('input', newOrderedArray);
    },

    changeIsActiveValue(item) {
      item.isActive = !item.isActive;
      this.onChange(this.value);
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.item {
  overflow: hidden;

  &__text {
    margin: 0 20px;
  }

  &__switch {
    float: right;
  }
}
</style>
