<template>
  <div>
    <span class="handle">
      <font-awesome-icon icon="bars" />
    </span>
    <slot>
      <span class="text">
        {{ text }}
      </span>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'DraggableListItem',
  props: {
    text: {
      type: String,
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.handle {
  float: left;
}
</style>
